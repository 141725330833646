import React, { useContext } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import OrganizationManagement from "./Pages/OrganizationManagement";
import Dashboard from "./Pages/Dashboard";
import Settings from "./Pages/Settings";
import Login from "./Pages/Login";
import MainLayout from "./MainLayout";
import PageNotFound from "./Pages/PageNotFound";
import { AuthContext, AuthProvider } from "../src/contexts/AuthContext";
import PrivateRoute from "../src/Components/PrivateRoute";

const App = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          
          <Route element={<MainLayout />}>
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />            
          <Route path="/organizations" element={<PrivateRoute><OrganizationManagement /></PrivateRoute>} />
          <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
          <Route path="*" element={<PrivateRoute><PageNotFound /></PrivateRoute>} />
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;

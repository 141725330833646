// import React, { useEffect, useState } from 'react';
// import Chart from 'react-apexcharts';
// import axios from 'axios';
// import Card from './Card';

// const StackedBarChart = ({ amPm }) => {
//   const [chartData, setChartData] = useState({
//     categories: [],
//     series: [],
//   });

//   const backendUrl = process.env.REACT_APP_BACKEND_URL

//   const fetchChartData = async (amPm) => {
//     try {
//       const response = await axios.get(
//         `${backendUrl}/llm_analysis/hits_by_hour/?am_pm=${amPm}`
//       );
//       const data = response.data;

//       const categories = data.map((item) => item.hour);
//       const cachedResponses = data.map((item) => item.cache_true_count);
//       const totalQueries = data.map((item) => item.total_count);

//       setChartData({
//         categories: categories,
//         series: [
//           {
//             name: 'Cached Responses',
//             data: cachedResponses,
//           },
//           {
//             name: 'Total Queries',
//             data: totalQueries,
//           },
//         ],
//       });
//     } catch (error) {
//       console.error('Error fetching chart data:', error);
//     }
//   };

//   useEffect(() => {
//     fetchChartData(amPm);
//   }, [amPm]);

//   const options = {
//     chart: {
//       type: 'bar',
//       stacked: true,
//       toolbar: {
//         show: true,
//         tools: {
//           download: false,
//         },
//       },
//     },
//     plotOptions: {
//       bar: {
//         horizontal: false,
//         columnWidth: '20%',
//         borderRadius: 4,
//       },
//     },
//     stroke: {
//       width: 0,
//       colors: ['#36489e'],
//     },
//     xaxis: {
//       categories: chartData.categories, 
//     },
//     yaxis: {
//       tickAmount: 10,
//       max: 1000,
//     },
//     grid: {
//       strokeDashArray: 12,
//       borderColor: '#e0e0e0',
//     },
//     fill: {
//       opacity: 1,
//     },
//     legend: {
//       position: 'top',
//       horizontalAlign: 'left',
//       enabled:true
//     },
//     dataLabels: {
//       enabled: false,
//     },
//     colors: ['#36489e', '#d84848'], // Blue for Cached Responses, Red for Total Queries
//     legend: {
//       show: false,
//     },
//   };

//   return (
//     <div>
//       <Chart options={options} series={chartData.series} type="bar" height={350} />
//     </div>
//   );
// };

// const Dashboard = () => {
//   const [amPm, setAmPm] = useState('pm'); 

//   const handleAmPmChange = (event) => {
//     setAmPm(event.target.value); 
//   };

//   return (
//     <div className="flex items-center justify-center mt-10 gap-4 flex-wrap">
//       <Card width="1430px" height="500px">
//         <div className="border-b w-full mb-4">
//           <div className="flex justify-between items-center">
//             <h1 className="font-bold text-xl mb-2">
//               Total Queries Vs Cached Responses
//             </h1>
//             <div>
//               {/* Dropdown for AM/PM */}
//               <select
//                 value={amPm}
//                 onChange={handleAmPmChange}
//                 className="p-2 border rounded mb-2"
//               >
//                 <option value="am">AM</option>
//                 <option value="pm">PM</option>
//               </select>
//             </div>
//           </div>
//         </div>

//         <StackedBarChart amPm={amPm} />
//       </Card>
//     </div>
//   );
// };

// export default Dashboard;

import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import axios from 'axios';
import Card from './Card';

const StackedBarChart = ({ amPm }) => {
  const [chartData, setChartData] = useState({
    categories: [],
    series: [],
  });

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const fetchChartData = async (amPm) => {
    try {
      let url = `${backendUrl}/llm_analysis/hits_by_hour/`;

      // If amPm is provided, append the filter to the request URL
      if (amPm) {
        url += `?am_pm=${amPm}`;
      }

      const response = await axios.get(url);
      const data = response.data;

      const categories = data.map((item) => item.hour);
      const cachedResponses = data.map((item) => item.cache_true_count);
      const totalQueries = data.map((item) => item.total_count);

      setChartData({
        categories: categories,
        series: [
          {
            name: 'Cached Responses',
            data: cachedResponses,
          },
          {
            name: 'Total Queries',
            data: totalQueries,
          },
        ],
      });
    } catch (error) {
      console.error('Error fetching chart data:', error);
    }
  };

  useEffect(() => {
    fetchChartData(amPm); // Initial fetch without `amPm`, and later with `amPm`
  }, [amPm]);

  const options = {
    chart: {
      type: 'bar',
      stacked: true,
      toolbar: {
        show: true,
        tools: {
          download: false,
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '20%',
        borderRadius: 4,
      },
    },
    stroke: {
      width: 0,
      colors: ['#36489e'],
    },
    xaxis: {
      categories: chartData.categories,
    },
    yaxis: {
      tickAmount: 10,
      max: 1000,
    },
    grid: {
      strokeDashArray: 12,
      borderColor: '#e0e0e0',
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      enabled: true,
    },
    dataLabels: {
      enabled: false,
    },
    colors: ['#36489e', '#d84848'], // Blue for Cached Responses, Red for Total Queries
    legend: {
      show: false,
    },
  };

  return (
    <div>
      <Chart options={options} series={chartData.series} type="bar" height={350} />
    </div>
  );
};

const Dashboard = () => {
  const [amPm, setAmPm] = useState(''); // Start with an empty string to load default data

  const handleAmPmChange = (event) => {
    setAmPm(event.target.value); // Set AM or PM based on dropdown selection
  };

  return (
    <div className="flex items-center  mt-10 gap-4 flex-wrap">
      <Card width="1470px" height="500px">
        <div className="border-b w-full mb-4">
          <div className="flex justify-between items-center">
            <h1 className="font-bold text-xl mb-2">
              Total Queries Vs Cached Responses
            </h1>
            <div>
              {/* Dropdown for AM/PM */}
              <select
                value={amPm}
                onChange={handleAmPmChange}
                className="p-2 border rounded mb-2"
              >
                <option value="">All</option> {/* Default to show all (without am_pm filter) */}
                <option value="am">AM</option>
                <option value="pm">PM</option>
              </select>
            </div>
          </div>
        </div>

        <StackedBarChart amPm={amPm} />
      </Card>
    </div>
  );
};

export default Dashboard;


import React, { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [authTokens, setAuthTokens] = useState(() =>
    Cookies.get("authTokens") ? JSON.parse(Cookies.get("authTokens")) : null
  );
  const [user, setUser] = useState(() =>
    Cookies.get("user") ? JSON.parse(Cookies.get("user")) : null
  );
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const navigate = useNavigate();

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const loginUser = async (email, password) => {
    try {
      setMessage("");
      setMessageType("");
      const response = await axios.post(`${backendUrl}/user/login/`, {
        email,
        password,
      });
      const data = response.data;
      if (data.data.role !== "admin" && data.data.role !== "member") {
        setMessage("");
        setMessageType("");
        logoutUser(); 
        setMessage("Unauthorized user");
        setMessageType("error");
        return;
      }
      const decodedToken = jwtDecode(data.token.access);
      const expirationTime = decodedToken.exp * 1000; 
      setAuthTokens(data.token);
      setUser(data.data);
      setMessage("Login Successful");
      setMessageType("success");
      Cookies.set("authTokens", JSON.stringify(data.token), { expires: 1 }); 
      Cookies.set("user", JSON.stringify(data.data), { expires: 1 });
      Cookies.set("tokenExpiration", expirationTime, { expires: 1 }); 
      navigate("/");
    } catch (error) {
      console.error("Login failed:", error);
      setMessage("Login Failed, Check your credentials");
      setMessageType("error");
    }
  };

  const logoutUser = () => {
    setAuthTokens(null);
    setUser(null);
    setMessage("");
    Cookies.remove("authTokens");
    Cookies.remove("user");
    Cookies.remove("tokenExpiration");
    navigate("/login");
  };

  const isAdmin = user?.role === "admin";
  console.log(isAdmin)
  const refreshToken = async () => {
    try {
      const response = await axios.post(
        `${backendUrl}/user/token/refresh/`,
        {
          refresh: authTokens.refresh,
        }
      );
      const data = response.data;
      const decodedToken = jwtDecode(data.access); // Decode the new access token
      const expirationTime = decodedToken.exp * 1000;
      setAuthTokens((prevTokens) => ({
        ...prevTokens,
        access: data.access,
      }));

      Cookies.set(
        "authTokens",
        JSON.stringify({
          ...authTokens,
          access: data.access,
        }),
        { expires: 7 }
      );
      Cookies.set("tokenExpiration", expirationTime, { expires: 7 });
    } catch (error) {
      console.error("Refresh token failed:", error);
      logoutUser();
    }
  };


  useEffect(() => {
    const tokenExpiration = Cookies.get("tokenExpiration");
    if (tokenExpiration && Date.now() > parseInt(tokenExpiration)) {
      logoutUser();
    }
  }, []);

  useEffect(() => {
    if (authTokens) {
      const refreshInterval = setInterval(() => {
        refreshToken();
      }, 15 * 60 * 1000); 
      return () => clearInterval(refreshInterval);
    }
  }, [authTokens]);

  useEffect(() => {
    setLoading(false);
  }, []);


  return (
    <AuthContext.Provider
      value={{ user, authTokens, loginUser, logoutUser, message, messageType, isAdmin }}
    >
      {loading ? null : children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };

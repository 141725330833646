import { useState, useEffect } from "react";
import axios from "axios";

const useFetchTotalCostAndCountById = (organizationId) => {
  const [data, setData] = useState(null);
  const [dateData, setDateData] = useState(null); // State for data with the current date
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    if (!organizationId) {
      setData(null);
      setDateData(null);
      setError(null); 
      return;
    }

    const fetchTotalCostAndCount = async () => {
      try {
        setLoading(true);
        setError(null);

        // First API call (current data)
        const response = await axios.get(
          `${backendUrl}/llm_analysis/get_total_cost_and_count_by_id/${organizationId}/`
        );
        if (response.data && response.data.length > 0) {
          setData(response.data);
        } else {
          setData(null);
        }

        const currentDate = new Date().toISOString().split('T')[0];

        const dateResponse = await axios.get(
          `${backendUrl}/llm_analysis/get_total_cost_and_count_by_id/${organizationId}/?date=${currentDate}`
        );
        if (dateResponse.data && dateResponse.data.length > 0) {
          setDateData(dateResponse.data);
        } else {
          setDateData(null);
        }
      } catch (error) {
        setError("No data for this organization");
      } finally {
        setLoading(false);
      }
    };

    fetchTotalCostAndCount();
  }, [organizationId]);

  return { data, dateData, loading, error };
};

export default useFetchTotalCostAndCountById;

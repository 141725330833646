import React, { useState } from "react";
import FormField from "./FormField"; 
import ActionButtons from "./ActionButtons"; 
import useCreateOrganization from "../hooks/useCreateOrganization";
import Toaster from "../Components/Toaster";

const AddOrganizationForm = () => {
  const { createOrganization, loading, errorMessages } = useCreateOrganization();
  const [invError, setInvError] = useState(null);
  const [webError, setWebError] = useState(null);
  const [formData, setFormData] = useState({
    orgName: '',
    webUrl: '',
    city:'',
    country: '',
    industry: '',
    assignLimit: '',
    phoneNumber: '',
    contactName: '',
    contactPhone: '+92',
    contactEmail: '',
    position: '',
    secondaryContactName: '',
    secondaryContactPhone: '',
    secondaryContactEmail: '',
    secondaryPosition: '',
    tablePrefix:'',
  });

  const [showSecondaryContact, setShowSecondaryContact] = useState(false);

  const [toasterMessage, setToasterMessage] = useState(null);
  const [toasterType, setToasterType] = useState(null);
  const [isDownloadEnabled, setIsDownloadEnabled] = useState(false);
  const [password, setPassword] = useState(null)

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
  
    if (type === 'number' && value < 0) {
      return; // Stop if negative value for number input
    }
  
    if (['orgName', 'industry', 'city', 'country', 'contactName', 'position', 'secondaryContactName', 'secondaryPosition', 'webUrl'].includes(name)) {
      const stringValue = value.replace(/[0-9]/g, ''); // Remove any numbers
      setFormData({
        ...formData,
        [name]: stringValue
      });
    } else if (name === 'contactPhone' || name === 'secondaryContactPhone') {
      const phoneValue = value.replace(/[^\d]/g, ""); // Only allow digits
      const formattedPhone = `+92${phoneValue.substring(2, 12)}`;
      if (phoneValue.length !== 12) {
        setInvError("Invalid phone number, must be 12 digits.");
      } else {
        setInvError(null); // Clear any existing phone error
      }
      setFormData({
        ...formData,
        [name]: formattedPhone
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };
  

  const handleSubmit = async () => {
    setToasterMessage("");
    setToasterType("");
    setPassword(null);

    if (!formData.webUrl.endsWith('.com')) {
      setWebError("Website URL must end with .com");
    }
    else{
      setWebError("");
    }
    try {
      const result = await createOrganization(formData);
      console.log("Organization created:", result);
      setToasterMessage("Organization added successfully");
      setToasterType("success");
      setIsDownloadEnabled(true)
      if (result?.organization && result?.organization?.password) {
        setPassword(result?.organization?.password);
      }
    } catch (error) {
      console.error("Failed to create organization:", error);
      setToasterMessage("Failed to add organization");
      setToasterType("error");
    }
  };
  
  return (
    <div className=" mx-auto p-0">
      <h2 className="text-[#397db5] font-bold mb-4 text-xl">
        Add Organization
      </h2>
      <h2 className="text-black font-bold mb-3 text-lg">Organization Detail</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
      
        <FormField
          type="text"
          label="Organization Name"
          name="orgName"
          value={formData.orgName}
          onChange={handleInputChange}
          placeholder="Enter organization name"
          error={errorMessages?.organization?.name?.[0]}  // Display the error
        />
        <FormField
          label="Website URL"
          name="webUrl"
          value={formData.webUrl}
          onChange={handleInputChange}
          type="text"
          placeholder="Enter organization website URL"
          error={webError}
        />
        <FormField
          label="Industry"
          name="industry"
          value={formData.industry}
          onChange={handleInputChange}
          type="text"
          placeholder="Enter industry"
          error={errorMessages?.organization?.industry?.[0]}

        />
        <FormField
          label="City"
          name="city"
          value={formData.city}
          onChange={handleInputChange}
          type="text"
          placeholder="Enter city"
          error={errorMessages?.organization?.city?.[0]}
        /> 
        <FormField
          label="Country/Region"
          name="country"
          value={formData.country}
          onChange={handleInputChange}
          type="text"
          placeholder="Enter country"
          error={errorMessages?.organization?.country?.[0]}

        /> 
        <FormField
          label="Address"
          name="address"
          value={formData.address}
          onChange={handleInputChange}
          type="text"
          placeholder="Enter office address"
          error={errorMessages?.organization?.address?.[0]}

        />
         <FormField
          label="Assign limit"
          name="assignLimit"
          value={formData.assignLimit}
          onChange={handleInputChange}
          type="number"
          min={0}
          error={errorMessages?.limit?.assigned_limit?.[0]}

        />
        <FormField
          label="Table Prefix"
          name="tablePrefix"
          value={formData.tablePrefix}
          onChange={handleInputChange}
          type="text"
        />
      </div>
      <h2 className="text-black font-bold mb-6 text-lg">Point of Contact</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <FormField
          type="text"
          label="Contact Person Name"
          name="contactName"
          value={formData.contactName}
          onChange={handleInputChange}
          placeholder="Enter contact person's name"
          error={errorMessages?.organization?.poc?.[0]?.name?.[0]}

        />
        <FormField
          label="Position/Title"
          name="position"
          value={formData.position}
          onChange={handleInputChange}
          type="text"
          placeholder="Enter position (e.g., CEO, IT Manager)"
          error={errorMessages?.organization?.poc?.[0]?.title?.[0]}

        />
        <FormField
          label="Email Address"
          name="contactEmail"
          value={formData.contactEmail}
          onChange={handleInputChange}
          type="email"
          placeholder="Enter contact person's email"
          error={errorMessages?.organization?.poc?.[0]?.email?.[0]}

        />
        <FormField
          label="Phone Number"
          name="contactPhone"
          value={formData.contactPhone}
          onChange={handleInputChange}
          type="text"
          placeholder="Enter phone number (with country code)"
          error={invError}
        />
      </div>
      <div className="mt-4 mb-6 flex justify-center border-t">
        <button
          className="text-black flex items-center mt-4"
          onClick={() => setShowSecondaryContact(!showSecondaryContact)}
        >
          <span className="mr-2 rounded-full w-6 text-center bg-[#397dd4] text-md font-bold text-white"> {showSecondaryContact ? '-' : '+'} </span> {/* Switch between + and - */}
          Add Secondary Point of Contact
        </button>
      </div>

      {showSecondaryContact && (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          <FormField
            type="text"
            label="Secondary Contact Person Name"
            name="secondaryContactName"
            value={formData.secondaryContactName}
            onChange={handleInputChange}
          />
          <FormField
            label="Position/Title"
            name="secondaryPosition"
            value={formData.secondaryPosition}
            onChange={handleInputChange}
            type="text"
          />
          <FormField
            label="Email Address"
            name="secondaryContactEmail"
            value={formData.secondaryContactEmail}
            onChange={handleInputChange}
            type="email"
          />
          <FormField
            label="Phone Number"
            name="secondaryContactPhone"
            value={formData.secondaryContactPhone}
            onChange={handleInputChange}
            type="text"
          />
        </div>
      )}
      {toasterMessage && <Toaster message={toasterMessage} type={toasterType} />}
      <ActionButtons onSave={handleSubmit} isDownloadEnabled={isDownloadEnabled} formData={formData} password={password}/>{" "}
    </div>
  );
};

export default AddOrganizationForm;

import React, { useEffect, useState } from "react";

const Toaster = ({ message, type }) => {
  const [visible, setVisible] = useState(true);
  const [fade, setFade] = useState(false);

  useEffect(() => {
    const fadeIn = setTimeout(() => {
      setFade(true);
    }, 100); // Delay to start fade in

    const fadeOut = setTimeout(() => {
      setFade(false);
      setTimeout(() => {
        setVisible(false);
      }, 500); // Smooth fade-out before removal
    }, 3000); // Display time before fading out

    return () => {
      clearTimeout(fadeIn);
      clearTimeout(fadeOut);
    };
  }, []);

  if (!visible) return null;

  return (
    <div
      className={`fixed left-1/2 transform -translate-x-1/2 top-6 p-4 rounded-lg shadow-lg text-white transition-all duration-500 ease-in-out ${
        fade ? "opacity-100 translate-y-0" : "opacity-0 translate-y-4"
      } ${type === "success" ? "bg-green-500" : "bg-red-500"}`}
      style={{
        willChange: "opacity, transform", // Enhances smoothness
        zIndex: 1000, // Ensure toaster is on top of other elements
      }}
    >
      <div className="flex items-center">
        {type === "success" ? (
          <svg
            className="w-6 h-6 mr-2 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 13l4 4L19 7"
            />
          </svg>
        ) : (
          <svg
            className="w-6 h-6 mr-2 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        )}
        <span>{message}</span>
      </div>
    </div>
  );
};

export default Toaster;

import { useContext, useState } from "react";
import axios from "axios";
import { AuthContext } from "../contexts/AuthContext";

const useUpdateOrganization = () => {
  const { authTokens } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [valError, setValError] = useState(null);
  const [error, setError] = useState(null);

  const updateOrganization = async (orgId, formData) => {
    setLoading(true);
    setError(null);

    try {
      const data = {
      organization: {
        id: orgId,
        name: formData.orgName,
        city: formData.city,
        country: formData.country,
        industry: formData.industry,
        website_url: formData.webUrl,
        address: formData.address,
        is_active: true,
        poc: [
          {
            email: formData.contactEmail,
            name: formData.contactName,
            phone: formData.contactPhone,
            title: formData.position,
            is_primary: true,
          },
        ],
      },
        limit:{
        assigned_limit: formData.assignLimit,
        remaining_limit: formData.remainingLimit || 0,
        }};

      if (
        formData.secondaryContactName ||
        formData.secondaryPosition ||
        formData.secondaryContactEmail ||
        formData.secondaryContactPhone
      ) {
        data.poc?.push({
          email: formData.secondaryContactEmail,
          name: formData.secondaryContactName,
          phone: formData.secondaryContactPhone,
          title: formData.secondaryPosition,
          is_primary: false,
        });
      }

      const response = await axios.put(
        `${backendUrl}/organization/update/${orgId}/`,
        data,
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
        }
      );
      setLoading(false);
      return response.data;
    } catch (err) {
      setValError(err?.response?.data)
      setLoading(false);
      setError(err);
      throw err;
    }
  };

  return { updateOrganization, loading, error, valError };
};

export default useUpdateOrganization;

import React, { useEffect, useState } from "react";
import FormField from "./FormField"; // Importing the FormField component
import ActionButtonsForEdit from "./ActionButtonsForEdit";
import Card from "../Components/Card";
import useFetchOrganizationDetail from "../hooks/useFetchOrganizationDetail";
import useUpdateOrganization from "../hooks/useUpdateOrganization";
import Toaster from "../Components/Toaster";
import Loader from "../Components/Loader";

const EditOrganizationForm = ({orgId,onBack}) => {
  const { organizationDetail, loading, error } = useFetchOrganizationDetail(orgId);
  const { updateOrganization, loading: updating, error: updateError, valError } = useUpdateOrganization();
  const [webError, setWebError] = useState(null)
  const [formData, setFormData] = useState({
    orgName: '',
    webUrl: '',
    city: '',
    country:"",
    address:"",
    industry: '',
    assignLimit: '',
    phoneNumber: '',
    contactName: '',
    contactPhone: '+92',
    contactEmail: '',
    position: '',
    secondaryContactName: '',
    secondaryContactPhone: '',
    secondaryContactEmail: '',
    secondaryPosition: '',
    password:""
  });

  const [toasterMessage, setToasterMessage] = useState(null);
  const [toasterType, setToasterType] = useState(null);
  const [password, setPassword] = useState(null);
  const [invError, setInvError] = useState(null)

  useEffect(() => {
    if (organizationDetail) {
      const org = organizationDetail
      console.log(org)
      const primaryContact = organizationDetail.poc.find(contact => contact.is_primary);
      const secondaryContact = organizationDetail.poc.find(contact => !contact.is_primary);

      setFormData({
        orgName: org.name || "",
        webUrl: org.website_url || "",
        city: org.city || "",
        industry: org.industry || "",
        address: org.address || "",
        country: org.country || "",
        assignLimit: org.assigned_limit || "",
        contactName: primaryContact?.name || "",
        contactPhone: primaryContact?.phone?.startsWith("+92") ? primaryContact?.phone : "+92" + primaryContact?.phone,
        contactEmail: primaryContact?.email || "",
        position: primaryContact?.title || "",
        secondaryContactName: secondaryContact?.name || "",
        secondaryContactPhone: secondaryContact?.phone || "",
        secondaryContactEmail: secondaryContact?.email || "",
        secondaryPosition: secondaryContact?.title || "",
        password:org.password || "",
      });
    }
  }, [organizationDetail]);

  const [showSecondaryContact, setShowSecondaryContact] = useState(false);

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
  
    if (type === 'number' && value < 0) {
      return; 
    }
  
    if (['orgName', 'industry', 'city', 'country', 'contactName', 'position', 'secondaryContactName', 'secondaryPosition'].includes(name)) {
      const stringValue = value.replace(/[0-9]/g, '');
      setFormData({
        ...formData,
        [name]: stringValue
      });
    } else if (name === 'contactPhone' || name === 'secondaryContactPhone') {
      const phoneValue = value.replace(/[^\d]/g, "");
      const formattedPhone = `+92${phoneValue.substring(2, 12)}`;
      if (phoneValue.length !== 12) {
        setInvError("Invalid phone number, must be 12 digits.");
      } else {
        setInvError(null); // Clear any existing phone error
      }
      setFormData({
        ...formData,
        [name]: formattedPhone,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  

     const handleSubmit = async () => {
      setToasterMessage("");
      setToasterType("");
      if (!formData.webUrl.endsWith('.com')) {
        setWebError("Website URL must end with .com");
      }
      else{
        setWebError("");
      }
    try {
      // const updatedData = await updateOrganization(orgId, formData);
      // console.log("Updated data:", updatedData);
      const updatedData = await updateOrganization(orgId, {
        ...formData,
        assignLimit: parseInt(formData.assignLimit, 10) || 0 
      });
      if (updatedData?.organization && updatedData?.organization?.password) {
        setFormData(prev => ({
          ...prev,
          password: updatedData?.organization?.password,
        }));
      }
      setToasterMessage("Data updated successfully")
      setToasterType("success")
    } catch (error) {
      console.error("Failed to update organization:", error);
      setToasterMessage("Failed to update data")
      setToasterType("error")
    }
  };

  if(loading) return <Loader/>;
  return (
    <div className=" mx-auto p-0">
      <h2 className="text-[#397db5] font-bold mb-4 text-xl">
        Edit Organization
      </h2>
      <h2 className="text-black font-bold mb-3 text-lg">Organization Detail</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
        <FormField
          type="text"
          label="Organization Name"
          name="orgName"
          value={formData.orgName}
          onChange={handleInputChange}
          error={valError?.name}
        />
        <FormField
          label="Website URL"
          name="webUrl"
          value={formData.webUrl}
          onChange={handleInputChange}
          type="text"
          error={webError}
        />
        <FormField
          label="Industry"
          name="industry"
          value={formData.industry}
          onChange={handleInputChange}
          type="text"
          error={valError?.industry}
        />
        <FormField
          label="City"
          name="city"
          value={formData.city}
          onChange={handleInputChange}
          type="text"
          error={valError?.city}
        /> 
        <FormField
          label="Country/Region"
          name="country"
          value={formData.country}
          onChange={handleInputChange}
          type="text"
          error={valError?.country}
        /> 
        <FormField
          label="Address"
          name="address"
          value={formData.address}
          onChange={handleInputChange}
          type="text"
          error={valError?.address}
        />
         <FormField
          label="Assign limit"
          name="assignLimit"
          value={formData.assignLimit}
          onChange={handleInputChange}
          type="number"
          min={0}
        />
      </div>
      <h2 className="text-black font-bold mb-6 text-lg">Point of Contact</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <FormField
          type="text"
          label="Contact Person Name"
          name="contactName"
          value={formData.contactName}
          onChange={handleInputChange}
          error={valError?.poc?.[0]?.name?.[0]}
        />
        <FormField
          label="Position/Title"
          name="position"
          value={formData.position}
          onChange={handleInputChange}
          type="text"
          error={valError?.poc?.[0]?.title?.[0]}
        />
        <FormField
          label="Email Address"
          name="contactEmail"
          value={formData.contactEmail}
          onChange={handleInputChange}
          type="email"
          error={valError?.poc?.[0]?.email?.[0]}
        />
        <FormField
          label="Phone Number"
          name="contactPhone"
          value={formData.contactPhone}
          onChange={handleInputChange}
          type="text"
          error={invError}
        />
      </div>
      <div className="mt-4 mb-6 flex justify-center border-t">
        <button
          className="text-black flex items-center mt-4"
          onClick={() => setShowSecondaryContact(!showSecondaryContact)}
        >
          <span className="mr-2 rounded-full w-6 text-center bg-[#397dd4] text-md font-bold text-white"> {showSecondaryContact ? '-' : '+'} </span> {/* Switch between + and - */}
          Add Secondary Point of Contact
        </button>
      </div>

      {showSecondaryContact && (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          <FormField
            type="text"
            label="Secondary Contact Person Name"
            name="secondaryContactName"
            value={formData.secondaryContactName}
            onChange={handleInputChange}
          />
          <FormField
            label="Position/Title"
            name="secondaryPosition"
            value={formData.secondaryPosition}
            onChange={handleInputChange}
            type="text"
          />
          <FormField
            label="Email Address"
            name="secondaryContactEmail"
            value={formData.secondaryContactEmail}
            onChange={handleInputChange}
            type="email"
          />
          <FormField
            label="Phone Number"
            name="secondaryContactPhone"
            value={formData.secondaryContactPhone}
            onChange={handleInputChange}
            type="text"
          />
        </div>
      )}
            {toasterMessage && <Toaster message={toasterMessage} type={toasterType} />}
      <ActionButtonsForEdit onSave={handleSubmit} formData={formData} password={formData.password}/>
    </div>
  );
};

export default EditOrganizationForm;

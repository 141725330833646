import React, { useContext, useState } from "react";
import Card from "../Components/Card";
import DataTable from "../Tables/AdminTable"; // Corrected import
import StackedBarChart from "../Components/StackedBarChart";
import TotalApiHits from "../Tables/TotalApiHits";
import TotalLLMCost from "../Components/TotalLLMCost";
import llmIcon from "../assets/llmcost.jpg";
import apiIcon from "../assets/apihits.jpg";
import orgIcon from "../assets/orgs.jpg";
import { AuthContext } from "../contexts/AuthContext";
import useFetchOrganizations from "../hooks/useFetchOrganizations";
import useLLMAnalysis from "../hooks/useLLMAnalysis";
import useTotalLLMCost from "../hooks/useTotalLLMCost";
import Loader from "../Components/Loader";

const Dashboard = () => {
  const { organizations, loading, error, setOrganizations } =
    useFetchOrganizations();
  const { data } = useLLMAnalysis();
  const { totalCost } = useTotalLLMCost();
  const [selectedCard, setSelectedCard] = useState(null);
  const { message } = useContext(AuthContext);

  console.log(message);
  const cardData = [
    {
      name: "Total Organizations",
      count: organizations ? organizations.length : "0",
      table: <DataTable onBackToDashboard={() => setSelectedCard(null)} />,
      icon: orgIcon,
    },
    {
      name: "Total Api Hits/Cache Hits",
      count: `${data?.total_requests} / ${data?.cache_hits}`,
      table: <TotalApiHits />,
      icon: apiIcon,
    },
    {
      name: "Total LLM Cost",
      count: totalCost?.total_cost
        ? `$${totalCost.total_cost.toFixed(5)}`
        : "$ 0",
      table: <TotalLLMCost />,
      icon: llmIcon,
    },
  ];

  if(loading) return <Loader/>;
  return (
    
    <div>
      {selectedCard !== null ? (
        <div>
          <Card>
            <div className="mt-4">{cardData[selectedCard].table}</div>
          </Card>
        </div>
      ) : (
        <>
          <div className="flex gap-4  flex-wrap cursor-pointer">
            {cardData.map((card, i) => (
              <Card
                key={i}
                width="480px"
                height="230px"
                name={card.name}
                count={card.count}
                mt={16}
                mb={4}
                icon={card.icon}
                border={true}
                borderColor={"#3b7cb7"}
                onClick={() => setSelectedCard(i)}
              />
            ))}
          </div>
              <StackedBarChart /> 
        </>
      )}
    </div>
  );
};

export default Dashboard;

const ActionButtons = ({ onSave, isDownloadEnabled, formData, password }) => {
  const handleDownload = () => {
    const data = `
      Email: ${formData.contactEmail}
      Password: ${password}
    `;

    const blob = new Blob([data], { type: 'text/plain' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'organization_credentials.txt';
    link.click();
  };
  
  return (
    <div className="mt-6 flex items-center space-x-2 flex-wrap max-sm:justify-center">
      <button
        type="button"
        disabled={!isDownloadEnabled} // Enable the button based on prop
        className={`bg-transparent ${
          !isDownloadEnabled ? 'disabled:text-[#cccccc] disabled:cursor-not-allowed disabled:border-[#cccccc]' : 'text-[#3d7bb5]'
        } rounded-full py-3 px-9 text-md border border-[#3d7bb5]`}
        onClick={handleDownload} // Add the download handler
      >
        Download Credentials
      </button>
      <button
        type="button"
        className="bg-[#397db5] text-white rounded-full hover:bg-[#397dd4] px-12 py-3 font-bold"
        onClick={onSave}
      >
        Save
      </button>
    </div>
  );
};

export default ActionButtons;

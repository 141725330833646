const FormField = ({ label, name, value, onChange, type = "text", placeholder, min, inputmode,error }) => {
    return (
      <div>
        <label className="block text-[16px] font-medium text-gray-700 mb-3">{label}</label>
        <input
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          className="mt-1 block w-full rounded-full border border-[#91a5bd] p-4 focus:none"
          placeholder={placeholder}
          min = {min}
          inputMode={inputmode}
          required
        />
        <p className="text-[#ff0000] text-sm">{error}</p>
      </div>
    );
  };
  
  export default FormField;
  
import React from 'react';
import { Link } from 'react-router-dom';
const PageNotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen  text-center">
      <img 
        src={"https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif" }
        alt="404 Not Found" 
        className="w-96 mb-8"
      />
      <h1 className="text-4xl font-bold text-gray-800 mb-4">Oops! Page not found.</h1>
      <p className="text-lg text-gray-500 mb-8">
        The page you're looking for doesn't exist or has been moved.
      </p>
      <Link
        to="/"
        className="px-6 py-3 text-white bg-[#3d7bb5] rounded-full shadow-md transition duration-300"
      >
        Go Back Home
      </Link>
    </div>
  );
};

export default PageNotFound;

import React from "react";

const Loader = () => {
  return (
      <div className="loader flex justify-center">
        <div className="circle border-t-4 border-b-4 border-blue-500 rounded-full w-16 h-16 animate-spin"></div>
      </div>
  );
};

export default Loader;

import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { AuthContext } from "../contexts/AuthContext";

const useUserData = (userId) => {
    const {authTokens} = useContext(AuthContext)
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    const fetchUser = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${backendUrl}/user/${userId}/`,
            {
                headers: {
                  Authorization: `Bearer ${authTokens.access}`,
                },
              }
        );
        setUser(response.data);
      } catch (err) {
        setError(err);
        console.log(err.message)
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, [userId]);

  return { user, loading, error };
};

export default useUserData;

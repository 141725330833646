import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../contexts/AuthContext';

const useTotalLLMCost = () => {
    const {authTokens} = useContext(AuthContext);
    const [totalCost, setTotalCost] = useState(null); 
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`${backendUrl}/llm_analysis/llm_total_cost/`,
                    {
                        headers: {
                          Authorization: `Bearer ${authTokens.access}`,
                        },
                      }
                );
                setTotalCost(response.data); 
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return { totalCost, loading, error };
};

export default useTotalLLMCost;

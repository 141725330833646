import React, { useState } from "react";
import ViewLogOfTotalLLMCost from "../Tables/ViewLogOfTotalLLMCost";
import Card from "./Card";
import useFetchOrganizations from "../hooks/useFetchOrganizations";
import useFetchTotalCostAndCountById from "../hooks/useFetchTotalCostAndCountById";
import Loader from "./Loader";
import QueriesTable from "../Tables/QueriesTable";
import { FaFile } from "react-icons/fa";

const TotalLLMCost = () => {
  const {
    organizations,
    loading: orgLoading,
    error: orgError,
  } = useFetchOrganizations();
  const [selectedCompany, setSelectedCompany] = useState("");
  const [showQueriesTable, setShowQueriesTable] = useState(false); // State to control QueriesTable rendering

  const selectedOrganization = organizations.find(
    (org) => org.name === selectedCompany
  );
  const organizationId = selectedOrganization?.id;
  const {
    data,
    loading: costLoading,
    error: costError,
    dateData,
  } = useFetchTotalCostAndCountById(organizationId);
  console.log(data);

  const handleCompanyChange = (event) => {
    setSelectedCompany(event.target.value);
    setShowQueriesTable(false);
  };

  const handleLogClick = () => {
    setShowQueriesTable(true);
  };

  return (
    <div className="w-full container mx-auto px-2 overflow-y-scroll">
                <h1 className="text-[#3d74a4] font-bold mb-4 text-xl">Total LLM Cost</h1>

      <Card height={750} padding={0}>
        {!showQueriesTable && (
          <div className="flex justify-between items-center mb-8 flex-wrap">
            <h1 className="text-lg font-semibold text-[#4482ba]">
              Select Organization
            </h1>
            <div className="flex flex-wrap items-center justify-center text-center">
              <button className="px-4 py-2 text-gray-600">View</button>
              <select
                className="flex items-center px-[30px] py-[17.5px] w-[231px] h-[59px] gap-[24px] rounded-full border border-[#92A7BE] text-gray-600 max-sm:w-full"
                style={{ background: "var(--Surface-Color-100)" }}
                value={selectedCompany}
                onChange={handleCompanyChange}
                disabled={orgLoading || orgError} // Disable if loading or error
              >
                <option value="" disabled>
                  {orgLoading ? "Loading..." : "Select the company"}
                </option>
                {organizations.map((org) => (
                  <option key={org.id} value={org.name}>
                    {org.name}
                  </option>
                ))}
              </select>
              {orgError && <p className="text-red-500">{orgError}</p>}
            </div>
          </div>
        )}

        {showQueriesTable ? (
          <QueriesTable organization={selectedOrganization} orgName={selectedCompany}/>
        ) : !selectedCompany ? (
          <>
            <div className="flex justify-center items-center mt-[10%]">
              <img
                className="h-[253px] w-[253px]"
                src="/iconTotalLLMCost.svg"
                alt="Cost Icon"
              />
            </div>
            <div className="flex justify-center items-center flex-col mt-6">
              <p className="text-[#07224D] font-medium px-4 py-2 rounded-full">
                Select an Organization
              </p>
              <p className="text-[#92a7be] text-sm mt-2">
                To view the LLM cost, select an organization
              </p>
            </div>
          </>
        ) : (
          <div className="flex justify-center items-center">
            <div className="overflow-x-auto overflow-y-auto w-full">
              <table className="min-w-full bg-[000] border-gray-300">
                <thead>
                  <tr className="bg-[#f6fbfd] px-4 py-2 border text-left text-[#231F20] font-inter text-[16px] font-bold">
                    <th className="px-4 py-2 border text-left text-[#231F20] font-inter text-[16px] font-bold">
                      Total API Calls
                    </th>
                    <th className="px-4 py-2 border text-left text-[#231F20] font-inter text-[16px] font-bold">
                      Duration
                    </th>
                    <th className="px-4 py-2 border text-left text-[#231F20] font-inter text-[16px] font-bold">
                      Total Cost
                    </th>
                    <th className="px-4 py-2 border text-left text-[#231F20] font-inter text-[16px] font-bold">
                      View Logs
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!costLoading && !costError && data && data.length > 0 ? (
                    <>
                      {/* Row for general data */}
                      <tr>
                        <td className="px-4 py-2 border text-[#666687] text-left font-inter text-[14px] font-normal capitalize">
                          {data[0]?.total_count || 0}
                        </td>
                        <td className="px-4 py-2 border text-[#666687] text-left font-inter text-[14px] font-normal capitalize">
                          Overall
                        </td>
                        <td className="px-4 py-2 border text-[#666687] text-left font-inter text-[14px] font-normal capitalize">
                          {data[0]?.total_cost
                            ? `${data[0].total_cost} USD`
                            : "0 USD"}
                        </td>
                        <td className="px-4 py-2 border text-left">
                          <button onClick={handleLogClick}>
                            <FaFile />
                          </button>
                        </td>
                      </tr>

                      {dateData && dateData.length > 0 && (
                        <tr>
                          <td className="px-4 py-2 border text-[#666687] text-left font-inter text-[14px] font-normal capitalize">
                            {dateData[0]?.total_count || 0}
                          </td>
                          <td className="px-4 py-2 border text-[#666687] text-left font-inter text-[14px] font-normal capitalize">
                            Today
                          </td>
                          <td className="px-4 py-2 border text-[#666687] text-left font-inter text-[14px] font-normal capitalize">
                            {dateData[0]?.total_cost
                              ? `${dateData[0].total_cost} USD`
                              : "0 USD"}
                          </td>
                          <td className="px-4 py-2 border text-left">
                            <button onClick={handleLogClick}>
                              <FaFile />
                            </button>
                          </td>
                        </tr>
                      )}
                    </>
                  ) : (
                    <tr>
                      <td colSpan="4" className="text-center py-4">
                        {costLoading ? (
                          <Loader />
                        ) : (
                          costError || "No data available"
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </Card>
    </div>
  );
};

export default TotalLLMCost;

import React, { useState } from "react";
import { FaSearch, FaTimes } from "react-icons/fa";
import { queriesData } from "./queriesData";
import useFetchQueries from "../hooks/useFetchQueries";

const QueriesTable = ({ organization, onBack, orgName }) => {
  const { queries, loading, error } = useFetchQueries(organization.id); // Fetch queries using the organization ID
  const [globalSearch, setGlobalSearch] = useState("");
  const [dateFilter, setDateFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(8);

  const [columnFilters, setColumnFilters] = useState({
    user_query: "",
    response: "",
    created_at: "",
  });

  const [activeFilters, setActiveFilters] = useState({
    user_query: false,
    response: false,
    created_at: false,
  });

  const filteredData = queries.filter((row) => {
    // Ensure the values are strings or provide default empty strings
    const userQuery = row.user_query ? row.user_query.toLowerCase() : "";
    const response = row.response ? row.response.toLowerCase() : "";
    const createdAt = row.created_at ? row.created_at.toLowerCase() : "";

    // Global Search match
    const matchesGlobalSearch =
      userQuery.includes(globalSearch.toLowerCase()) ||
      response.includes(globalSearch.toLowerCase()) ||
      createdAt.includes(globalSearch.toLowerCase());

    // Column Filter match
    const columnUserQuery = columnFilters.query
      ? columnFilters.query.toLowerCase()
      : "";
    const columnResponse = columnFilters.response
      ? columnFilters.response.toLowerCase()
      : "";
    const columnCreatedAt = columnFilters.date
      ? columnFilters.date.toLowerCase()
      : "";

    const matchesColumnFilters =
      userQuery.includes(columnUserQuery) &&
      response.includes(columnResponse) &&
      createdAt.includes(columnCreatedAt);

    // Date match
    const matchesDate =
      dateFilter === "" ||
      (row.created_at && row.created_at.startsWith(dateFilter));

    return matchesGlobalSearch && matchesColumnFilters && matchesDate;
  });

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const currentData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const handleColumnFilterChange = (e, column) => {
    setColumnFilters({
      ...columnFilters,
      [column]: e.target.value,
    });
    setCurrentPage(1);
  };

  const toggleFilter = (column) => {
    setActiveFilters({
      ...activeFilters,
      [column]: !activeFilters[column],
    });
  };

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  return (
    <div className="container mx-auto ">
      <h1 className="text-[#3d74a4] font-bold mb-4 text-xl">{orgName}</h1>

      {/* Global Search */}
      <div className="flex justify-between mb-4 items-center max-sm:flex-wrap">
        <input
          type="text"
          placeholder="Search..."
          className="px-8 py-3 border rounded-full w-full"
          value={globalSearch}
          onChange={(e) => setGlobalSearch(e.target.value)}
        />
        <FaSearch className="absolute top-4 left-2 text-gray-500" />
        <input
          type="date"
          className="border p-3  ml-2 rounded-full"
          value={dateFilter}
          onChange={(e) => setDateFilter(e.target.value)}
        />
      </div>

      {/* Table */}
      <div className="overflow-x-auto rounded-2xl max-sm:h-[600px]">
        <table className="min-w-full bg-white border border-gray-300 truncate">
          <thead>
            <tr className="bg-[#f6fbfe]">
              <th className="px-4 py-2 border">
                <div className="flex items-center justify-between">
                  {activeFilters.query ? (
                    <div className="flex items-center">
                      <input
                        type="text"
                        className="w-40 p-1 bg-transparent focus:outline-none"
                        value={columnFilters.query}
                        onChange={(e) => handleColumnFilterChange(e, "query")}
                        placeholder="Search Query"
                        autoFocus
                      />
                      <FaTimes
                        className="cursor-pointer ml-2 text-red-500"
                        onClick={() => toggleFilter("query")} // Close filter
                      />
                    </div>
                  ) : (
                    <>
                      <span>Query</span>
                      <FaSearch
                        className="cursor-pointer ml-2"
                        onClick={() => toggleFilter("query")}
                      />
                    </>
                  )}
                </div>
              </th>

              <th className="px-4 py-2 border">
                <div className="flex items-center justify-between">
                  {activeFilters.response ? (
                    <div className="flex items-center">
                      <input
                        type="text"
                        className="w-[700px] p-1 bg-transparent focus:outline-none"
                        value={columnFilters.response}
                        onChange={(e) =>
                          handleColumnFilterChange(e, "response")
                        }
                        placeholder="Search Response"
                        autoFocus
                      />
                      <FaTimes
                        className="cursor-pointer ml-2 text-red-500"
                        onClick={() => toggleFilter("response")}
                      />
                    </div>
                  ) : (
                    <>
                      <span>Response</span>
                      <FaSearch
                        className="cursor-pointer ml-2"
                        onClick={() => toggleFilter("response")}
                      />
                    </>
                  )}
                </div>
              </th>

              <th className="px-4 py-2 border">
                <div className="flex items-center justify-between">
                  {activeFilters.date ? (
                    <div className="flex items-center">
                      <input
                        type="text"
                        className="w-40 p-1 bg-transparent focus:outline-none"
                        value={columnFilters.date}
                        onChange={(e) => handleColumnFilterChange(e, "date")}
                        placeholder="YYYY-MM-DD"
                        autoFocus
                      />
                      <FaTimes
                        className="cursor-pointer ml-2 text-red-500"
                        onClick={() => toggleFilter("date")} // Close filter
                      />
                    </div>
                  ) : (
                    <>
                      <span className="whitespace-nowrap">Date & Time</span>
                      <FaSearch
                        className="cursor-pointer ml-2"
                        onClick={() => toggleFilter("date")}
                      />
                    </>
                  )}
                </div>
              </th>
            </tr>
          </thead>

          <tbody>
            {currentData.length > 0 ? (
              currentData.map((row, index) => (
                <tr key={index}>
                  <td className="px-4 py-3 border text-[#626183]">
                  <div className="whitespace-pre-line overflow-y-scroll scrollbar-webkit h-[40px] w-[350px]">{row?.user_query}</div>
                  </td>
                  <td className="px-4 py-2 border text-[#626183]">
                    <div className="whitespace-pre-line overflow-y-scroll scrollbar-webkit h-[45px]">{row?.response}</div>
                  </td>
                  <td className="px-4 py-2 border text-[#626183]">
                    {formatDate(row?.created_at)}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3" className="text-center py-4">
                  No data found
                </td>
              </tr>
            )}
            <tr>
              <td colSpan="3" className="text-center py-4">
                <div className="flex items-center justify-between mt-4">
                  <div>
                    <span className="mr-2 px-4">
                      Total Queries ({filteredData.length})
                    </span>
                    <label>
                      Showing
                      <select
                        className="mx-2 border border-gray-300 p-1"
                        value={itemsPerPage}
                        onChange={handleItemsPerPageChange}
                      >
                        <option value={8}>8</option>
                        <option value={10}>10</option>
                        <option value={11}>11</option>
                        <option value={20}>20</option>
                      </select>
                      Per Page
                    </label>
                  </div>
                  <div className="px-4">
                    <button
                      className="px-3 py-1 border rounded mr-2"
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>

                    {/* Display first page */}
                    {currentPage > 3 && (
                      <button
                        className="px-3 py-1 border rounded mx-1"
                        onClick={() => handlePageChange(1)}
                      >
                        1
                      </button>
                    )}

                    {/* Show dots if needed */}
                    {currentPage > 4 && <span>...</span>}

                    {/* Display pages around current page */}
                    {Array.from({ length: 5 }, (_, i) => {
                      const page = currentPage - 2 + i;
                      if (page > 1 && page < totalPages) {
                        return (
                          <button
                            key={page}
                            className={`px-3 py-1 border rounded mx-1 ${
                              currentPage === page
                                ? "bg-blue-500 text-white"
                                : ""
                            }`}
                            onClick={() => handlePageChange(page)}
                          >
                            {page}
                          </button>
                        );
                      }
                      return null;
                    })}

                    {/* Show dots if needed */}
                    {currentPage < totalPages - 3 && <span>...</span>}

                    {/* Display last page */}
                    {currentPage < totalPages - 2 && (
                      <button
                        className="px-3 py-1 border rounded mx-1"
                        onClick={() => handlePageChange(totalPages)}
                      >
                        {totalPages}
                      </button>
                    )}

                    <button
                      className="px-3 py-1 border rounded ml-2"
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Pagination and Items per Page Controls */}
    </div>
  );
};

export default QueriesTable;

import React from "react";
import * as XLSX from "xlsx";

const ExportModal = ({ isOpen, onClose, data, currentPageData, fileName }) => {
  const exportToExcel = (dataToExport, sheetName, isAllData) => {
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

    const exportFileName = isAllData ? "API_Hits.xlsx" : `${fileName}.xlsx`;
    XLSX.writeFile(workbook, exportFileName);
    onClose();
  };

  return (
    <div
      className={`fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50 transition-opacity duration-300 ${
        isOpen ? "opacity-100 pointer-events-auto" : "opacity-0 pointer-events-none"
      }`}
    >
      <div
        className={`bg-white p-8 rounded-lg shadow-lg w-[550px] transform transition-transform duration-300 ${
          isOpen ? "scale-100" : "scale-90"
        }`}
      >
        <h2 className="text-lg font-bold mb-4">Export Data</h2>
        <div className="flex justify-center gap-2">
        <button
          className="block w-full bg-[#3d7bb5] text-white px-4 py-2 rounded "
          onClick={() =>
            exportToExcel(currentPageData, "Current_Page_Data", false)
          }
        >
          Export current page's Data
        </button>
        <button
          className="block w-full bg-[#3d7bb5] text-white px-4 py-2 rounded"
          onClick={() => exportToExcel(data, "All_Data", true)}
        >
          Export all the Data
        </button>
        </div>
        <button
          className="block w-full bg-gray-500 text-white px-4 py-2 rounded mt-4"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default ExportModal;

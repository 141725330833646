import { useContext, useState } from "react";
import axios from "axios";
import { AuthContext } from "../contexts/AuthContext";

const useUpdateUser = () => {
    const { authTokens } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [valError, setValError] = useState(null);
    const backendUrl = process.env.REACT_APP_BACKEND_URL;


    const updateUser = async (userId, formData) => {
        setLoading(true);
        setError(null);
        setSuccess(false);

        const payload = {
            name: formData.name,
            phone: formData.phone,
            email: formData.email,
            role: formData.role,
            designation: formData.designation,
        };

        try {
            const response = await axios.put(`${backendUrl}/user/${userId}/`, payload,
                {
                    headers: {
                        Authorization: `Bearer ${authTokens.access}`,
                    },
                }
            );
            setSuccess(true);
            return response.data;
        } catch (err) {
          const errorMessage = err.response?.data?.errors?.role
        ? err.response.data.errors.role.join(", ")
        : "An error occurred while adding the user.";
            setError(errorMessage);
            setValError(err?.response?.data)
        } finally {
            setLoading(false);
        }
    };

    return { updateUser, loading, error, success, valError, setValError };
};

export default useUpdateUser;

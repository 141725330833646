import React from "react";

const ActionButtonsAddUser = ({ onSave, formData, userAdded, password }) => {

  const downloadCredentials = () => {
    const element = document.createElement("a");
    const file = new Blob(
      [
        `Email: ${formData.email}\n`,
        `Password: ${password}`
       
      ],
      { type: "text/plain" }
    );
    element.href = URL.createObjectURL(file);
    element.download = `${formData.name}_credentials.txt`;
    document.body.appendChild(element);
    element.click();
  };

  return (
    <div className="mt-6 flex items-center space-x-2 flex-wrap max-sm:justify-center">
      {/* Download button triggers the download function */}
      <button
        type="button"
        onClick={downloadCredentials} // Directly trigger download function
        disabled={!userAdded} // Button is enabled only if user was added
        className={`bg-transparent ${
          !userAdded
            ? "disabled:text-[#cccccc] disabled:cursor-not-allowed disabled:border-[#cccccc]"
            : "text-[#3d7bb5] border-[#3d7bb5]"
        } rounded-full py-3 px-9 text-md border`}
      >
        Download Credentials
      </button>
      <button
        type="button"
        className="bg-[#397db5] text-white rounded-full hover:bg-[#397dd4] px-12 py-3 font-bold"
        onClick={onSave} // Save user
      >
        Save
      </button>
    </div>
  );
};

export default ActionButtonsAddUser;

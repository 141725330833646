const ActionButtonsEditUser = ({ onSave, formData }) => {
  
  const handleDownload = () => {
    const userData = `
      Email: ${formData.email}
      Password: ${formData.password}
    `;

    const blob = new Blob([userData], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'user_credentials.txt';
    link.click();
    window.URL.revokeObjectURL(url);
  };
    return (
      <div className="mt-6 flex items-center space-x-2 flex-wrap max-sm:justify-center">
        <button
          type="button"
          className="bg-transparent disabled:text-[#cccccc] rounded-full disabled:cursor-not-allowed py-3 px-9 text-md border disabled:border-[#cccccc] border-[#3d7bb5] text-[#3d7bb5]"
          onClick={handleDownload}
        >
          Download Credentials
        </button>
        <button
          type="button" 
          className="bg-[#397db5] text-white rounded-full hover:bg-[#397dd4] px-12 py-3 font-bold"
          onClick={onSave}
        >
          Update
        </button>
      </div>
    );
  };
  
  export default ActionButtonsEditUser;
  
import React, { useEffect, useState } from "react";
import FormField from "./FormField"; // Importing the FormField component
import ActionButtonsEditUser from "./ActionButtonsEditUser";
import useUserData from "../hooks/useUserData";
import useUpdateUser from "../hooks/useUpdateUser";
import Loader from "../Components/Loader";
import Toaster from "../Components/Toaster";

const EditUserForm = ({ userId }) => {
  const { user, loading: loadingUser, error: errorUser } = useUserData(userId);
  const {
    updateUser,
    loading: loadingUpdate,
    error: errorUpdate,
    success,
    valError,
    setValError
  } = useUpdateUser();
  const [toasterMessage, setToasterMessage] = useState(null);
  const [toasterType, setToasterType] = useState(null);
  const [invError, setInvError] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    phone: "+92",
    email: "",
    role: "",
    designation: "",
    password:"",
  });

  useEffect(() => {
    if (user) {
      setFormData({
        name: `${user.name}`,
        phone: user.phone.startsWith("+92") ? user.phone : "+92" + user.phone,
        email: user.email,
        role: user.role,
        designation: user.designation || "",
        password: user.password,
      });
    }
  }, [user]);

  console.log(user)
  const handleInputChange = (e) => {
    const { name, value } = e.target;
  
    if (name === "phone") {
      const phoneValue = value.replace(/[^\d]/g, ""); // Only digits allowed for phone
      const formattedPhone = `+92${phoneValue.substring(2, 12)}`; 
      setFormData({ ...formData, phone: formattedPhone });
    } else if (name === "name") {
      const stringValue = value.replace(/[0-9]/g, ""); // Remove any numbers
      setFormData({ ...formData, name: stringValue });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  

  useEffect(() => {
    if (success) {
      setToasterMessage("User updated successfully");
      setToasterType("success");
      setValError("");
      setInvError("");
    } else if (errorUpdate) {
      setToasterMessage(errorUpdate || "An error occurred");
      setToasterType("error");
    }
  }, [success, errorUpdate]);
  const handleSubmit = async () => {
    setToasterMessage("");
    setToasterType("");

    if (formData.phone.length < 13) {
      setInvError("Invalid phone number");
      return;
    }
    await updateUser(userId, formData);
    console.log(formData)
  };

  if (loadingUser) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  if (errorUser) {
    return <div>Error loading user data</div>;
  }

  return (
    <div className="mx-auto p-0">
      <h2 className="text-[#3d7bb5] font-bold mb-4 text-xl">Edit User</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
        <FormField
          type="text"
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          error={valError?.name}
        />
        <FormField
          type="text"
          label="Phone"
          name="phone"
          value={formData.phone}
          onChange={handleInputChange}
          error={valError?.phone || invError}
        />
        <FormField
          type="email"
          label="Email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          error={valError?.email}
        />
        <div>
          <label htmlFor="role" className="block text-[16px] font-medium text-gray-700 mb-3">
            Role
          </label>
          <select
            id="role"
            name="role"
            value={formData.role}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-full border border-[#91a5bd] p-4 focus:none"
          >
            <option value="admin">Admin</option>
            <option value="member">Member</option>
          </select>
        </div>
      </div>
      {toasterMessage && <Toaster message={toasterMessage} type={toasterType} />}
      <ActionButtonsEditUser onSave={handleSubmit} formData={formData}/>
    </div>
  );
};

export default EditUserForm;

import { useContext, useState } from "react";
import axios from "axios";
import { AuthContext } from "../contexts/AuthContext";

const useAddUser = () => {
  const { authTokens } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [valError, setValError] = useState(null)
  const backendUrl = process.env.REACT_APP_BACKEND_URL;


  const addUser = async (formData) => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    const [firstName, lastName] = formData.name.split(" ");
    const payload = {
      email: formData.email,
      name: firstName,
      role: formData.role,
      phone: formData.phone,
    };


    try {
      const response = await axios.post(
        `${backendUrl}/user/`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
        }
      );
      setSuccess(true);
      return { success: true, data: response.data };
    } catch (err) {
      const errorMessage = err.response?.data?.errors?.email
        ? err.response.data.errors.email.join(", ")
        : "An error occurred while adding the user.";

      setError(errorMessage);
      setValError(err?.response?.data);
      return { success: false, error: errorMessage };
    } finally {
      setLoading(false);
    }
  };

  return { addUser, loading, error, success, valError, setValError };
};

export default useAddUser;

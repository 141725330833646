import React, { useState, useEffect } from "react";
import { FaFile, FaSearch, FaTimes } from "react-icons/fa";
import "../Tables/Tables.css";
import useFetchOrganizations from "../hooks/useFetchOrganizations"; // Import the custom hook
import Loader from "../Components/Loader";
import QueriesTable from "./QueriesTable";

const DataTable = ({ onBackToDashboard }) => {
  const { organizations, loading, error } = useFetchOrganizations();
  const [orgName, setOrgName] = useState(null)
  const [selectedRow, setSelectedRow] = useState(null); // Track selected row for QueriesTable
  const [searchFilters, setSearchFilters] = useState({
    name: "",
    primary_email: "",
    primary_phone: "",
    city: "",
    country: "",
    industry: "",
    assigned_limit: "",
    remaining_limit:""
  });

  const [activeFilters, setActiveFilters] = useState({
    name: false,
    primary_email: false,
    primary_phone: false,
    city: false,
    country: false,
    industry: false,
    assigned_limit: false,
    remainig_limit: false
  });

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(12);

  useEffect(() => {
    if (!loading && !error && organizations.length) {
      setData(organizations);
    }
  }, [organizations, loading, error]);

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const currentData = data.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleOpenQueries = (row) => {
    setOrgName(row.name)
    setSelectedRow(row); 
  };
  const handleBackToTable = () => {
    setSelectedRow(null);
  };

  if (selectedRow) {
    return (
      <QueriesTable
      orgName={orgName}
        organization={selectedRow} 
        onBack={handleBackToTable}
      />
    );
  }


  const handleFilterChange = (e, key) => {
    const newFilters = { ...searchFilters, [key]: e.target.value };
    setSearchFilters(newFilters);

    const filteredData = organizations.filter((row) => {
      return Object.keys(newFilters).every((filterKey) => {
        if (newFilters[filterKey] === "") return true;
        if (typeof row[filterKey] === "number") {
          return row[filterKey]
            .toString()
            .includes(newFilters[filterKey]);
        }
        if (filterKey === "phone") {
          // Safely handle phone filtering
          const phone = row.poc?.[0]?.phone;
          return phone && phone.toString().includes(newFilters[filterKey]);
        }

        return row[filterKey]
          .toString()
          .toLowerCase()
          .includes(newFilters[filterKey].toLowerCase());
      });
    });
    setData(filteredData);
    setCurrentPage(1); // Reset to first page when search filter changes
  };

  const toggleFilter = (key) => {
    setActiveFilters((prevFilters) => ({
      ...prevFilters,
      [key]: !prevFilters[key],
    }));
  };

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to first page
  };

  const renderHeading = (key, label) => {
    return activeFilters[key] ? (
      <div className="flex items-center">
        <input
          type="text"
          className="focus:outline-none w-28 bg-transparent"
          value={searchFilters[key]}
          onChange={(e) => handleFilterChange(e, key)}
          placeholder={`Search`}
          autoFocus
        />
        <FaTimes
          className="cursor-pointer ml-2 text-red-500"
          onClick={() => toggleFilter(key)} // Close filter
        />
      </div>
    ) : (
      <div className="flex items-center justify-between">
        <span>{label}</span>
        <FaSearch
          className="cursor-pointer ml-2"
          onClick={() => toggleFilter(key)}
        />
      </div>
    );
  };

  return (
    <div className="container mx-auto px-4">
      <h1 className="text-[#3d74a4] font-bold mb-4 text-xl">Organizations</h1>
      {loading && <p><Loader/></p>}
      {error && <p>{error}</p>}
      {!loading && !error && (
        <div className="overflow-x-auto max-sm:h-screen">
          <table className="min-w-full bg-white border-gray-300">
            <thead>
              <tr className="bg-[#f6fbfe]">
                <th className="px-4 py-3 border">
                  {renderHeading("name", "Organization")}
                </th>
                <th className="px-4 py-2 border">
                  {renderHeading("email", "Email")}
                </th>
                <th className="px-4 py-2 border">
                  {renderHeading("phone", "Phone Number")}
                </th>
                <th className="px-4 py-2 border">{renderHeading("city", "City")}</th>
                <th className="px-4 py-2 border">{renderHeading("country", "Country")}</th>
                <th className="px-4 py-2 border">
                  {renderHeading("industry", "Industry")}
                </th>
                <th className="px-4 py-2 border">
                  {renderHeading("assigned_limit", "Assigned Limit")}
                </th>
                <th className="px-4 py-2 border">
                  {renderHeading("remaining_limit", "Remaining Limit")}
                </th>
                <th className="px-4 py-2 border text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentData.length > 0 ? (
                currentData.map((row) => (
                  <tr key={row.id}>
                    <td className="px-4 py-3 border text-[#626183]">{row.name}</td>
                    <td className="px-4 py-2 border text-[#626183]">{row.email}</td>
                    <td className="px-4 py-2 border text-[#626183]">{row.poc[0]?.phone}</td>
                    <td className="px-4 py-2 border text-[#626183]">{row.city}</td>
                    <td className="px-4 py-2 border text-[#626183]">{row.country || ""}</td>
                    <td className="px-4 py-2 border text-[#626183]">{row.industry}</td>
                    <td className="px-4 py-2 border text-[#626183]">{row.assigned_limit}</td>
                    <td className="px-4 py-2 border text-[#626183]">{row.remaining_limit}</td>
                    <td className="px-4 py-2 border text-center">
                      <button className="" onClick={()=> handleOpenQueries(row)}>
                        <FaFile />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="9" className="text-center py-4">
                    No data found
                  </td>
                </tr>
              )}
              <tr>
                <td colSpan="9" className="text-center py-4">
                  <div className="flex items-center justify-between mt-4">
                    <div>
                      <span className="mr-2 px-4">
                        Total Organizations ({data.length})
                      </span>
                      <label>
                        Showing
                        <select
                          className="mx-2 border border-gray-300 p-1"
                          value={itemsPerPage}
                          onChange={handleItemsPerPageChange}
                        >
                          <option value={5}>5</option>
                          <option value={10}>10</option>
                          <option value={12}>12</option>
                          <option value={20}>20</option>
                        </select>
                        Per Page
                      </label>
                    </div>
                    <div>
                      <button
                        className="px-3 py-1 border rounded mr-2"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        Previous
                      </button>
                      <span className="mx-2">
          Page {currentPage} of {totalPages}
        </span>
                      <button
                        className="px-3 py-1 border rounded ml-2"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default DataTable;

import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../contexts/AuthContext";

const useFetchOrganizationDetail = (orgId) => {
    const { authTokens } = useContext(AuthContext);
  const [organizationDetail, setOrganizationDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;


  useEffect(() => {
    if (orgId) {
      setLoading(true);
      axios
        .get(`${backendUrl}/organization/get_by_id/${orgId}/`,
            {
                headers: {
                  Authorization: `Bearer ${authTokens.access}`,
                },
              }
        )
        .then((response) => {
          setOrganizationDetail(response.data);
          setLoading(false);
        })
        .catch((error) => {
          setError(error.message);
          setLoading(false);
        });
    }
  }, [orgId]);

  return { organizationDetail, loading, error };
};

export default useFetchOrganizationDetail;

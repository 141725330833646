import React, { useContext, useState } from "react";
import {
  FaTachometerAlt,
  FaBuilding,
  FaCog,
  FaBars,
  FaTimes,
  FaSignOutAlt,
} from "react-icons/fa"; 
import logo from "../assets/logo.png";
import { Link, NavLink, useLocation } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false); 
  const location = useLocation(); 
  const {logoutUser} = useContext(AuthContext)

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="flex ">
      <aside
        className={`bg-white text-black fixed left-0 h-full w-66 p-4 z-50 transition-transform duration-300
          ${isOpen ? "translate-x-0" : "-translate-x-full"} 
          md:translate-x-0 md:w-72`}
      >
        <div className="flex items-center justify-between">
          <NavLink to={"/"}>
          <div className="flex items-center gap-3 p-4">
            <img src={logo} alt="logo" />
            <h1 className="text-3xl text-[#2b2762] font-bold" style={{fontFamily:"lekton"}}>App Pilot</h1>
          </div>
          </NavLink>
          <button
            onClick={toggleSidebar}
            className="md:hidden text-2xl focus:outline-none"
          >
            <FaTimes />
          </button>
        </div>

        <nav className="space-y-4 mt-14 w-full">
          <Link
            to="/"
            className={`flex items-center gap-2 py-3 px-2 rounded 
              ${
                location.pathname === "/" || location.pathname === "/dashboard"
                  ? "bg-[#e1ecf5] text-[#4272a8]"
                  : "hover:bg-[#e1ecf5] hover:text-[#4272a8]"
              }`}
              onClick={()=>setIsOpen(false)}
          >
            <FaTachometerAlt />
            Dashboard
          </Link>
          <Link
            to="/organizations"
            className={`flex items-center gap-2 py-3 px-2 rounded 
              ${
                location.pathname === "/organizations"
                  ? "bg-[#e1ecf5] text-[#4272a8]"
                  : "hover:bg-[#e1ecf5] hover:text-[#4272a8]"
              }`}
              onClick={()=>setIsOpen(false)}
          >
            <FaBuilding />
            Organization Management
          </Link>
          <Link
            to="/settings"
            className={`flex items-center gap-2 py-3 px-2 rounded 
              ${
                location.pathname === "/settings"
                  ? "bg-[#e1ecf5] text-[#4272a8]"
                  : "hover:bg-[#e1ecf5] hover:text-[#4272a8]"
              }`}
              onClick={()=>setIsOpen(false)}
          >
            <FaCog />
            Settings
          </Link>
        </nav>
        <div className="absolute bottom-8 left-0.5 w-full flex justify-center">
          <button
            className="flex items-center gap-2 py-3 px-4 rounded-full border hover:text-[#4272a8] border-[#92a6b9] w-11/12 text-[#92a6b9]"
            onClick={() => logoutUser()}
          >
            <FaSignOutAlt />
            Log Out
          </button>
        </div>
      </aside>

      <div
        className={`flex-1 min-h-screen p-6 transition-all duration-300 max-md:p-0
        ${isOpen ? "ml-64" : "ml-0"} 
        md:ml-64 md:p-8`}
      >
        
        {!isOpen && (
          <button
            onClick={toggleSidebar}
            className="md:hidden text-2xl mb-4 focus:outline-none fixed top-7 left-4 z-50"
          >
            <FaBars />
          </button>
        )}
      </div>
    </div>
  );
};

export default Sidebar;

import React, { useContext, useState } from "react";
import useFetchOrganizations from "../hooks/useFetchOrganizations"; // Adjust the import path
import DeleteModal from "../Components/DeleteModal";
import EditOrganizationForm from "../Forms/EditOrgForm";
import QueriesTable from "./QueriesTable";
import { FaFile, FaSearch, FaTrash } from "react-icons/fa";
import { FaPencil } from "react-icons/fa6";
import useDeleteOrganization from "../hooks/useDeleteOrganization";
import Loader from "../Components/Loader";
import Toaster from "../Components/Toaster";
import { AuthContext } from "../contexts/AuthContext";

const OrganizationTable = ({ onAddOrganizationClick }) => {
  const { organizations, loading, error, setOrganizations } = useFetchOrganizations();
  const {isAdmin} = useContext(AuthContext)
  console.log(isAdmin)
  const { deleteOrganization, loading: deleteLoading, error: deleteError } = useDeleteOrganization(); // Use the delete hook
  const [activeFilters, setActiveFilters] = useState({});
  const [filters, setFilters] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    city: "",
    country: "",
    industry: "",
    assignedLimit: "",
  });
  const [globalSearch, setGlobalSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [organizationToDelete, setOrganizationToDelete] = useState(null);

  const [showEditForm, setShowEditForm] = useState(false);
  const [organizationToEdit, setOrganizationToEdit] = useState(null);

  const [showQueriesTable, setShowQueriesTable] = useState(false);
  const [organizationToLog, setOrganizationToLog] = useState(null);
  const [toasterMessage, setToasterMessage] = useState(null);
  const [toasterType, setToasterType] = useState(null);
  const [orgName, setOrgName] = useState(null);

  const handleFilterChange = (e, column) => {
    setFilters({ ...filters, [column]: e.target.value });
  };

  const handleToggleFilter = (column) => {
    setActiveFilters((prev) => ({
      ...prev,
      [column]: !prev[column],
    }));
  };

  const handleSearch = () => {
    return organizations.filter((org) => {
      if (globalSearch) {
        return Object.values(org).some((value) => {
          if (typeof value === "number") {
            return value.toString().includes(globalSearch);
          }
          return typeof value === "string" && value.toLowerCase().includes(globalSearch.toLowerCase());
        });
      }
  
      return Object.keys(filters).every((key) => {
        if (filters[key] === "") return true;
  
        if (key === "phone") {
          const phone = org.poc?.[0]?.phone || "";
          return phone.toLowerCase().includes(filters[key].toLowerCase());
        }
        if (typeof org[key] === "number") {
          return org[key].toString().includes(filters[key]);
        }
          return typeof org[key] === "string" && org[key].toLowerCase().includes(filters[key].toLowerCase());
      });
    });
  };
  

  const filteredOrganizations = handleSearch();
  const totalPages = Math.ceil(filteredOrganizations.length / itemsPerPage);
  const displayedOrganizations = filteredOrganizations.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleDeleteClick = (organization) => {
    setShowDeleteModal(true);
    setOrganizationToDelete(organization);
  };

  const handleDeleteConfirm = () => {
    setToasterMessage("");
    setToasterType("");
    if (organizationToDelete) {
      deleteOrganization(organizationToDelete.id, () => {
        const updatedOrganizations = organizations.filter(
          (org) => org.id !== organizationToDelete.id
        );
        setOrganizations(updatedOrganizations);
        setShowDeleteModal(false);
        setOrganizationToDelete(null);
        setToasterMessage("Organization deleted successfully");
        setToasterType("success");
      });
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
    setOrganizationToDelete(null);
  };

  const handleEditClick = (organization) => {
    setOrganizationToEdit(organization);
    setShowEditForm(true);
  };

  const handleLogClick = (organization) => {
    setOrgName(organization.name)
    setOrganizationToLog(organization);
    setShowQueriesTable(true);
  };

  const handleBackToTable = () => {
    setShowEditForm(false);
    setShowQueriesTable(false);
  };

  return (
    <div className="container mx-auto">
      {loading ? (
        <p><Loader /></p>
      ) : error ? (
        <p>{error}</p>
      ) : showEditForm ? (
        <EditOrganizationForm orgId={organizationToEdit.id} onBack={handleBackToTable} />
      ) : showQueriesTable ? (
        <QueriesTable organization={organizationToLog} orgName={orgName} onBack={handleBackToTable} />
      ) : (
        <>
          <div className="flex justify-between mb-4 items-center max-sm:flex-wrap">
            <input
              type="text"
              className="px-8 py-3 border rounded-full w-full"
              placeholder="Search..."
              value={globalSearch}
              onChange={(e) => setGlobalSearch(e.target.value)}
            />
            {isAdmin &&
             <button
             className="bg-[#3d7bb5] text-white p-3 rounded-full  ml-2 w-52 max-sm:w-full"
             onClick={onAddOrganizationClick}
           >
             Add Organization
           </button> }
           
          </div>

          <div className="overflow-x-auto">
            <table className="min-w-full table-fixed">
              <thead className="bg-[#f6fbfe] top-0 w-28">
                <tr>
                  {[
                    { key: "name", label: "Name" },
                    { key: "email", label: "Email" },
                    { key: "phone", label: "Phone" },
                    { key: "city", label: "City" },
                    { key: "country", label: "Country" },
                    { key: "industry", label: "Industry" },
                    { key: "assigned_limit", label: "Assigned Limit" },
                  ].map((col) => (
                    <th className="px-4  min-w-[150px] border border-[#95a7bd]" key={col}>
                      {activeFilters[col.key] ? (
                        <div className="flex">
                          <input
                            type="text"
                            className="w-full bg-transparent"
                            placeholder={`Search `}
                            value={filters[col.key]}
                            onChange={(e) => handleFilterChange(e, col.key)}
                          />
                          <button
                            onClick={() => handleToggleFilter(col.key)}
                            className="ml-2 text-red-500 hover:text-red-700"
                          >
                            X
                          </button>
                        </div>
                      ) : (
                        <div className="flex justify-between items-center">
                          <span className="text-sm lg:text-base">
                            {col.label}
                          </span>
                          <FaSearch onClick={() => handleToggleFilter(col.key)} className="cursor-pointer ml-2" />
                        </div>
                      )}
                    </th>
                  ))}
                  {isAdmin && <th className="px-4 py-2  border border-[#95a7bd]">Action</th>}
                  
                </tr>
              </thead>
              <tbody>
                {displayedOrganizations.length > 0 ? (
                  displayedOrganizations.map((org, index) => (
                    <tr key={index} className="border-b h-12">
                      <td className=" border text-[#626183]">{org.name}</td>
                      <td className=" border text-[#626183]">{org.email}</td>
                      <td className=" border text-[#626183]">{org.poc[0]?.phone}</td>
                      <td className=" border text-[#626183]">{org.city}</td>
                      <td className=" border text-[#626183]">{org.country || "N/A"}</td>
                      <td className=" border text-[#626183]">{org.industry}</td>
                      <td className=" border text-[#626183]">{org.assigned_limit}</td>
                      {isAdmin && 
                      <td className=" border text-[#626183]">
                      <div className="flex">
                        <span className="cursor-pointer" onClick={() => handleLogClick(org)}>
                          <FaFile />
                        </span>
                        <span
                          className="ml-2 text-yellow-500 hover:text-yellow-700 cursor-pointer"
                          onClick={() => handleEditClick(org)}
                        >
                          <FaPencil />
                        </span>
                        <span
                          className="ml-2 text-red-500 hover:text-red-700 cursor-pointer"
                          onClick={() => handleDeleteClick(org)}
                        >
                          <FaTrash />
                        </span>
                      </div>
                    </td>
                      }
                      
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={isAdmin ? "8" : "7"} className="px-4 py-2 text-center">
                      No data found.
                    </td>
                  </tr>
                )}
                <tr>
                  <td colSpan={isAdmin ? "8" : "7"} className="px-4 py-2 text-center">
                    <div className="flex flex-col md:flex-row justify-between items-center mt-4 space-y-4 md:space-y-0">
                      <span className="text-sm">Total Organizations: {filteredOrganizations.length}</span>
                      <div className="flex items-center space-x-2">
                        <button
                          className="px-3 py-1 bg-gray-300 rounded hover:bg-gray-400"
                          onClick={() => handlePageChange(currentPage - 1)}
                          disabled={currentPage === 1}
                        >
                          Previous
                        </button>
                        <span className="text-sm">Page {currentPage} of {totalPages}</span>
                        <button
                          className="px-3 py-1 bg-gray-300 rounded hover:bg-gray-400"
                          onClick={() => handlePageChange(currentPage + 1)}
                          disabled={currentPage === totalPages}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <DeleteModal
            show={showDeleteModal}
            onDeleteConfirm={handleDeleteConfirm}
            onCancel={handleCancelDelete}
            what="organization"
          />
        </>
      )}
      {toasterMessage && <Toaster message={toasterMessage} type={toasterType} />}
    </div>
  );
};

export default OrganizationTable;

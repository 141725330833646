import { useContext, useState } from "react";
import axios from "axios";
import { AuthContext } from "../contexts/AuthContext";

const useCreateOrganization = () => {
    const { authTokens } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorMessages, setErrorMessages] = useState({});

  const backendUrl = process.env.REACT_APP_BACKEND_URL;


  const createOrganization = async (formData) => {
    setLoading(true);
    setErrorMessages({});  // Clear previous errors

    const data = {
      organization: {
        name: formData.orgName,
        city: formData.city,
        country: formData.country,
        industry: formData.industry,
        website_url: formData.webUrl,
        address: formData.address,
      poc: [
        {
          name: formData.contactName,
          title: formData.position,
          email: formData.contactEmail,
          phone: formData.contactPhone,
          is_primary: true,
        },
      ]
    },
    limit:{
        assigned_limit: formData.assignLimit,
        remaining_limit: formData.assignLimit,
        table_prefix: formData.tablePrefix || "" 
    }};
    

    if (formData.secondaryContactName || formData.secondaryContactEmail || formData.secondaryContactPhone || formData.secondaryPosition) {
      data.poc?.push({
        name: formData.secondaryContactName,
        title: formData.secondaryPosition,
        email: formData.secondaryContactEmail,
        phone: formData.secondaryContactPhone,
        is_primary: false,
      });
    }

    try {
      const response = await axios.post(
        `${backendUrl}/organization/create/`,
        data,
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
        }
      );
      setLoading(false);
      return response.data;
    } catch (err) {
      setLoading(false);
      if (err.response && err.response.data) {
        setErrorMessages(err.response.data);
      } else {
        setErrorMessages({ general: "An unexpected error occurred." });
      }
      throw err;
    }
  };


  return { createOrganization, loading, errorMessages };
};

export default useCreateOrganization;

import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../contexts/AuthContext";

const useFetchApiData = () => {
    const {authTokens} = useContext(AuthContext)
  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${backendUrl}/llm_analysis/get_all/`,
            {
                headers: {
                  Authorization: `Bearer ${authTokens.access}`,
                },
              }
        );
        const formattedData = response.data.map(item => ({
          query: item.user_query,
          response: item.response,
          date: new Date(item.created_at).toLocaleString(), // Formatting date
          organization: item.organization,
          cacheHit: item.cache ? "True" : "False",
        }));
        setApiData(formattedData);
      } catch (err) {
        setError("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { apiData, loading, error };
};

export default useFetchApiData;

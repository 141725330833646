import React from "react";

const Card = ({
  width,
  height,
  icon,
  name,
  count,
  children,
  mt,
  mb,
  onClick,
  padding,
  border,
  borderColor,
}) => {
  return (
    <div
      className="bg-white  rounded-3xl p-6"
      style={{
        width: width,
        height: height,
        padding: padding,
        border: border ? `2px solid transparent` : "none", // Set initial border to transparent
        cursor: onClick ? "pointer" : "default",
      }}
      onClick={onClick}
      onMouseEnter={(e) => {
        if (border) {
          e.currentTarget.style.border = `1px solid ${borderColor || "black"}`;
        }
      }}
      onMouseLeave={(e) => {
        if (border) {
          e.currentTarget.style.border = "2px solid transparent";
        }
      }}
    >
      {icon && (
        <img
          src={icon}
          alt={`${name} icon`}
          className="h-13 w-13 text-gray-400 mb-12"
        />
      )}

      <div style={{ marginTop: mt, marginBottom: mb }}>
        {name && (
          <div className="text-gray-500 text-md font-medium mb-1">{name}</div>
        )}
        {count && (
          <div className="text-[#3b7cb6] text-2xl font-bold">{count}</div>
        )}
      </div>

      {children && <div className="mt-4">{children}</div>}
    </div>
  );
};

export default Card;

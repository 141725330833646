import React, { useState } from "react";
import FormField from "./FormField";
import ActionButtonsAddUser from "./ActionButtonsAddUser";
import useAddUser from "../hooks/useAddUser";
import Toaster from "../Components/Toaster";
import useUserData from "../hooks/useUserData";

const AddUserForm = () => {
  const { addUser, loading, error, success, valError,setValError } = useAddUser();
  const [toasterMessage, setToasterMessage] = useState(null);
  const [invError, setInvError] = useState(null)
  const [toasterType, setToasterType] = useState(null);
  const [userAdded, setUserAdded] = useState(false);
  const [password, setPassword] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    phone: "+92",
    email: "",
    role: "",
    designation: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
  
    if (name === "phone") {
      const phoneValue = value.replace(/[^\d]/g, ""); // Only digits allowed for phone
      const formattedPhone = `+92${phoneValue.substring(2, 12)}`; 
      setFormData({ ...formData, phone: formattedPhone });
    } else if (name === "name") {
      const stringValue = value.replace(/[0-9]/g, ""); // Remove any numbers
      setFormData({ ...formData, name: stringValue });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  

  const handleSubmit = async () => {
    setToasterMessage("");
    setToasterType("");
    setUserAdded(false);

    if (formData.phone.length < 13) {
      setInvError("Invalid phone number");
      return;
    }
  
    const result = await addUser(formData);
    console.log(result)
    if (result.success) {
      setPassword(result?.data.password)
      setToasterMessage("User added successfully");
      setToasterType("success");
      setUserAdded(true);
      setValError("");
      setInvError("");
    } else {
      setToasterMessage(result.error);
      setToasterType("error");
    }
  };

  return (
    <div className="mx-auto p-0">
      <h2 className="text-[#3d7bb5] font-bold mb-4 text-xl">Add A User</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
        <FormField
          type="text"
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          placeholder="Enter name"
          error={valError?.name}
        />
        <FormField
          type="text"
          label="Phone"
          name="phone"
          value={formData.phone}
          onChange={handleInputChange}
          placeholder="Enter contact"
          error={valError?.phone || invError}
        />
        <FormField
          type="email"
          label="Email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          placeholder="Enter email"
          error={valError?.email}
        /><div>
        <label htmlFor="role" className="block text-[16px] font-medium text-gray-700 mb-3">
          Role
        </label>
        <select
          id="role"
          name="role"
          value={formData.role}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-full border border-[#91a5bd] p-4 focus:none"
        >
          <option value="" disabled hidden>
            Select a role
          </option>
          <option value="admin">Admin</option>
          <option value="member">Member</option>
        </select>
        <p className="text-[#ff0000] text-sm">{valError?.detail}</p>
      </div>
      </div>
      {toasterMessage && (
        <Toaster message={toasterMessage} type={toasterType} />
      )}
      <ActionButtonsAddUser
        onSave={handleSubmit}
        formData={formData}
        userAdded={userAdded}
        password={password}
      />
    </div>
  );
};

export default AddUserForm;

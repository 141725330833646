import { useContext, useState } from "react";
import axios from "axios";
import { AuthContext } from "../contexts/AuthContext";

const useDeleteOrganization = () => {
    const { authTokens } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;


  const deleteOrganization = (orgId, onSuccess) => {
    setLoading(true);
    axios
      .delete(`${backendUrl}/organization/delete/${orgId}/`,
        {
            headers: {
              Authorization: `Bearer ${authTokens.access}`,
            },
          }
      )
      .then(() => {
        setLoading(false);
        if (onSuccess) onSuccess(); // Call onSuccess callback if provided
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  };

  return { deleteOrganization, loading, error };
};

export default useDeleteOrganization;

import React, { useContext, useState } from "react";
import DeleteModal from "../Components/DeleteModal"; // Import your DeleteModal
import EditUserForm from "../Forms/EditUserForm";
import { FaSearch, FaTrash } from "react-icons/fa";
import { FaPencil } from "react-icons/fa6";
import { AuthContext } from "../contexts/AuthContext";
import useUsers from "../hooks/useUsers"; // Import the custom hook
import Loader from "../Components/Loader";
import useDeleteUser from "../hooks/useDeleteUser";
import Toaster from "../Components/Toaster";

const UserManagementTable = ({ onAddUserClick }) => {
  const { isAdmin } = useContext(AuthContext);
  const { users, loading, error, setUsers } = useUsers();
  const {
    deleteUser,
    loading: deletingUser,
    error: deleteError,
  } = useDeleteUser();
  const [toasterMessage, setToasterMessage] = useState(null);
  const [toasterType, setToasterType] = useState(null);
  const [activeFilters, setActiveFilters] = useState({});
  const [filters, setFilters] = useState({
    username: "",
    email: "",
    role: "",
  });
  const [globalSearch, setGlobalSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  const [showEditForm, setShowEditForm] = useState(false);
  const [userToEdit, setUserToEdit] = useState(null);

  const handleFilterChange = (e, column) => {
    setFilters({ ...filters, [column]: e.target.value });
  };

  const handleToggleFilter = (column) => {
    setActiveFilters((prev) => ({
      ...prev,
      [column]: !prev[column],
    }));
  };

  const handleSearch = () => {
    return users.filter((user) => {
      const fullName = `${user.first_name} ${user.last_name}`;
      if (globalSearch) {
        return (
          user.email.toLowerCase().includes(globalSearch.toLowerCase()) ||
          fullName.toLowerCase().includes(globalSearch.toLowerCase()) ||
          user.role.toLowerCase().includes(globalSearch.toLowerCase())
        );
      }
      return Object.keys(filters).every(
        (key) =>
          filters[key] === "" ||
          user[key].toLowerCase().includes(filters[key].toLowerCase())
      );
    });
  };

  const filteredUsers = handleSearch();
  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);
  const displayedUsers = filteredUsers.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleDeleteClick = (user) => {
    setShowDeleteModal(true);
    setUserToDelete(user);
  };

  const handleDeleteConfirm = () => {
    if (userToDelete) {
      const success = deleteUser(userToDelete.id);
      if (success) {
        const updatedUsers = users.filter(
          (user) => user.id !== userToDelete.id
        );
        setUsers(updatedUsers);
        setShowDeleteModal(false);
        setToasterMessage("");
        setToasterType("");
        
        setTimeout(() => {
          setToasterMessage("User deleted successfully");
          setToasterType("success");
        }, 100);
      } else {
        console.error("Failed to delete user");
      }
    }
  };
  
  const handleCancelDelete = () => {
    setShowDeleteModal(false);
    setUserToDelete(null);
  };

  const handleEditClick = (user) => {
    setUserToEdit(user.id);
    setShowEditForm(true);
  };

  const handleBackToTable = () => {
    setShowEditForm(false);
  };

  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="container mx-auto">
      {showEditForm ? (
        <EditUserForm
          user={userToEdit}
          onBack={handleBackToTable}
          userId={userToEdit}
        />
      ) : (
        <>
          <h2 className="text-[#3d7bb5] font-bold mb-4 text-lg">
            User Management
          </h2>
          <div className="flex mb-4">
            <input
              type="text"
              className="px-8 py-3 border rounded-full w-full"
              placeholder="Search..."
              value={globalSearch}
              onChange={(e) => setGlobalSearch(e.target.value)}
            />
            {isAdmin && (
              <button
                className="bg-[#3d7bb5] text-white p-3 rounded-full ml-2 w-52 max-sm:w-full"
                onClick={onAddUserClick}
              >
                Add User
              </button>
            )}
          </div>

          <div className="overflow-x-auto">
            <table className="min-w-full table-fixed">
              <thead className="bg-[#f6fbfe] top-0 w-28">
                <tr>
                  {[
                    { key: "name", label: "Username" },
                    { key: "email", label: "Email" },
                    { key: "role", label: "Role" },
                  ].map((col) => (
                    <th
                      className="px-4 py-3 w-2/2 border border-[#95a7bd]"
                      key={col}
                    >
                      {activeFilters[col.key] ? (
                        <div className="flex items-center">
                          <input
                            type="text"
                            className="w-full bg-transparent "
                            placeholder={`Search ${col.label}`}
                            value={filters[col.key]}
                            onChange={(e) => handleFilterChange(e, col.key)}
                          />
                          <button
                            className="ml-2 text-red-500"
                            onClick={() => handleToggleFilter(col.key)}
                          >
                            X
                          </button>
                        </div>
                      ) : (
                        <div className="flex justify-between items-center">
                          <span className="capitalize">{col.label}</span>
                          <FaSearch
                            onClick={() => handleToggleFilter(col.key)}
                            className="ml-2 cursor-pointer"
                          ></FaSearch>
                        </div>
                      )}
                    </th>
                  ))}
                  {isAdmin && <th className="px-4 py-2 border">Action</th>}
                </tr>
              </thead>
              <tbody>
                {displayedUsers.length > 0 ? (
                  displayedUsers.map((user, index) => (
                    <tr key={index} className="hover:bg-gray-100">
                      <td className="px-4 py-4 w-1/6 border text-[#626183]">
                        {user.name}
                      </td>
                      <td className="border px-4 py-2 text-[#626183]">
                        {user.email}
                      </td>
                      <td className="border px-4 py-2 text-[#626183]">
                        {user.role}
                      </td>
                      {isAdmin && (
                        <td className="border px-4 py-2">
                          <button
                            className="text-yellow-500 mr-2"
                            onClick={() => handleEditClick(user)}
                          >
                            <FaPencil />
                          </button>
                          <button
                            className="text-red-500"
                            onClick={() => handleDeleteClick(user)}
                          >
                            <FaTrash />
                          </button>
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={isAdmin ? "5" : "4"}
                      className="text-center py-4"
                    >
                      No users found.
                    </td>
                  </tr>
                )}
                <tr>
                  <td
                    colSpan={isAdmin ? "5" : "4"}
                    className="text-center py-2"
                  >
                    <div className="flex justify-between items-center mt-3">
                      <span className="px-2 text-sm">
                        Total Users: {filteredUsers.length}
                      </span>
                      <div className="flex">
                        <button
                          className="px-3 py-1 bg-gray-200"
                          onClick={() => handlePageChange(currentPage - 1)}
                          disabled={currentPage === 1}
                        >
                          Previous
                        </button>
                        <span className="px-4">
                          Page {currentPage} of {totalPages}
                        </span>
                        <button
                          className="px-3 py-1 bg-gray-200"
                          onClick={() => handlePageChange(currentPage + 1)}
                          disabled={currentPage === totalPages}
                        >
                          Next
                        </button>
                      </div>
                    </div>{" "}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <DeleteModal
            show={showDeleteModal}
            onDeleteConfirm={handleDeleteConfirm}
            onCancel={handleCancelDelete}
            what="user"
          />
        </>
      )}
      {toasterMessage && (
        <Toaster message={toasterMessage} type={toasterType} />
      )}
    </div>
  );
};

export default UserManagementTable;

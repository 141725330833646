import React, { useContext, useState } from "react";
import FormField from "../Forms/FormField";
import logo from "../assets/logo.png";
import { AuthContext } from "../contexts/AuthContext";
import Toaster from "../Components/Toaster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Import FontAwesome component
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"; // Import eye icons

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const { loginUser, message, messageType } = useContext(AuthContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    loginUser(email, password);
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState); // Toggle the visibility
  };

  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-[#f4f5f9]">
      {message && <Toaster message={message} type={messageType} />}
      <div className="flex justify-center mb-4 items-center gap-2">
        <img src={logo} alt="App Pilot" className="h-8" />
        <h2 className="text-2xl text-[#22205f] font-bold" style={{ fontFamily: "lekton" }}>
          App Pilot
        </h2>
      </div>
      <div className="w-full max-w-lg p-8 bg-white rounded-xl">
        <h2 className="text-center text-2xl font-semibold text-[#3d7bb5]">Sign In</h2>
        <p className="text-center text-[#9fb0c9] mb-6">Please enter your login information</p>
        <form onSubmit={handleSubmit}>
          <FormField
            label="Email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder="Email"
          />
          <div className="mt-4 relative ">
            <FormField
              label="Password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type={showPassword ? "text" : "password"} // Toggle between password and text
              placeholder="Password"
            />
            <span
              className="absolute inset-y-0 right-3 top-8 flex items-center cursor-pointer"
              onClick={togglePasswordVisibility}
            >
              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} className="text-gray-500" />
            </span>
          </div>
          <button
            type="submit"
            className="mt-6 w-full p-4 bg-[#3d7bb5] text-white text-sm font-medium rounded-full focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;

import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from './Components/Navbar';
import Sidebar from './Components/SideBar';

const MainLayout = () => {
  return (
    <div className="flex h-screen max-md:overflow-auto">
      <Navbar />
      <Sidebar />
      <div className="ml-2 mt-20 p-4 w-full bg-[#f4f5f9] max-md:ml-0">
        <Outlet />
      </div>
    </div>
  );
};

export default MainLayout;

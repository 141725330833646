import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../contexts/AuthContext';

const useFetchQueries = (organizationId) => {
    const {authTokens} = useContext(AuthContext)
  const [queries, setQueries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    if (!organizationId) return;
    
    const fetchQueries = async () => {
      try {
        const response = await axios.get(
          `${backendUrl}/llm_analysis/get_by_id/${organizationId}/`,
          {
            headers: {
              Authorization: `Bearer ${authTokens.access}`,
            },
          }
        );
        setQueries(response.data);
      } catch (err) {
        setError(err.message || 'Failed to fetch data');
      } finally {
        setLoading(false);
      }
    };

    fetchQueries();
  }, [organizationId]);

  return { queries, loading, error };
};

export default useFetchQueries;

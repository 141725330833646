import { useContext, useState } from "react";
import axios from "axios";
import { AuthContext } from "../contexts/AuthContext";

const useDeleteUser = () => {
    const {authTokens} = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;


  const deleteUser = async (userId) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.delete(`${backendUrl}/user/${userId}/`,
        {
            headers: {
              Authorization: `Bearer ${authTokens.access}`,
            },
          }
      );
      if (response.status === 200) {
        return true; 
      } else {
        throw new Error("Failed to delete user.");
      }
    } catch (err) {
      setError(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  return { deleteUser, loading, error };
};

export default useDeleteUser;

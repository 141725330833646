import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../contexts/AuthContext"; // Adjust the import path

const useFetchOrganizations = () => {
  const { authTokens } = useContext(AuthContext);
  const [organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;


  useEffect(() => {
    const fetchOrganizations = async () => {
      if (!authTokens) return;
      
      try {
        setLoading(true);
        const response = await axios.get(
          `${backendUrl}/organization/get_list/`,
          {
            headers: {
              Authorization: `Bearer ${authTokens.access}`,
            },
          }
        );
        setOrganizations(response.data);
        console.log(organizations)
        setLoading(false);
      } catch (error) {
        setError("Failed to fetch organizations.");
        setLoading(false);
      }
    };

    fetchOrganizations();
  }, [authTokens]);

  return { organizations, loading, error, setOrganizations };
};

export default useFetchOrganizations;

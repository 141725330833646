// import React, { useContext } from "react";
// import { useLocation } from "react-router-dom";
// import { AuthContext } from "../contexts/AuthContext";

// const Navbar = () => {
//   const location = useLocation();

//   const getHeading = () => {
//     switch (location.pathname) {
//       case "/":
//         return "Dashboard";
//         case "/dashboard":
//         return "Dashboard";
//       case "/organizations":
//         return "Organization Management";
//       case "/settings":
//         return "Settings";
//       default:
//         return "";
//     }
//   };
//   const {user} = useContext(AuthContext)

//   return (
//     <nav className="bg-white text-black fixed top-0 left-36 w-full h-20 flex items-center max-md:left-0">
//       <div className="container mx-auto px-4 flex justify-between items-center ">
//         <div className="text-xl font-bold ml-6">{getHeading()}</div>
//         <div className="flex space-x-4">
//           <div className="flex items-center gap-4">
//             <img
//               src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQIO4kw4CJYq4u79F13XGgysu28MPNqUbPG8Q&s"
//               alt="Omar Ali"
//               className="w-8 h-8 rounded-full"
//             />
//             <div>
//               <div className="text-xl font-bold text-black">{user?.name}<span className="max-sm:hidden"> {user?.last_name}</span> </div>
//               <div className="text-sm text-gray-500 max-sm:hidden">  {user?.role && user.role.charAt(0).toUpperCase() + user.role.slice(1).toLowerCase()}
//               </div>
//             </div>
           
//           </div>
//         </div>
//       </div>
//     </nav>
//   );
// };

// export default Navbar;

import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";

const Navbar = () => {
  const location = useLocation();

  const getHeading = () => {
    switch (location.pathname) {
      case "/":
      case "/dashboard":
        return "Dashboard";
      case "/organizations":
        return "Organization Management";
      case "/settings":
        return "Settings";
      default:
        return "";
    }
  };

  const { user } = useContext(AuthContext);

  return (
    <nav className="bg-white text-black fixed top-0 left-0 w-full h-20 px-6 flex items-center">
      <div className="w-full grid grid-cols-[1fr_auto] items-center">
        {/* Dashboard heading */}
        <div className="text-xl font-bold ml-72 max-sm:ml-6">
          {getHeading()}
        </div>

        {/* User profile */}
        <div className="flex justify-end items-center gap-4">
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQIO4kw4CJYq4u79F13XGgysu28MPNqUbPG8Q&s"
            alt="Omar Ali"
            className="w-10 h-10 rounded-full object-cover"
          />
          <div className="flex flex-col">
            <div className="text-lg font-bold text-black truncate max-w-xs">
              {user?.name} <span className="hidden md:inline">{user?.last_name}</span>
            </div>
            <div className="text-sm text-gray-500 hidden md:inline">
              {user?.role &&
                user.role.charAt(0).toUpperCase() +
                  user.role.slice(1).toLowerCase()}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

